import React, { useState } from 'react';
import { NavLink } from "react-router-dom";

const Navigation = ({ onPress }) => {
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = (e) => {
        e.stopPropagation();
        setMenuOpen(prevState => !prevState);
    };

    const closeMenu = () => {
        setMenuOpen(false);
    };

    return (
        <div className={`navigation ${menuOpen ? 'open' : ''}`}>
            <div className="containerName">
                <div className="name">Expedition-Wars</div>
            </div>
            <ul className={`menu ${menuOpen ? 'menu-open' : ''}`}>
                <li onClick={closeMenu}>
                    <NavLink to="/" className="lienMenu">Accueil</NavLink>
                </li>
                <li onClick={closeMenu}>
                    <NavLink to="/modal" className="lienMenu" onClick={() => { onPress("login") }}>Connexion</NavLink>
                </li>
                <li onClick={closeMenu}>
                    <NavLink to="/modal" className="lienMenu" onClick={() => { onPress("register") }}>Inscription</NavLink>
                </li>
            </ul>
            <div className="toggle" onClick={toggleMenu}>
                <i className={`fas ${menuOpen ? 'fa-times fermer' : 'fa-bars ouvrir'}`}></i>
            </div>
        </div>
    );
};

export default Navigation;
