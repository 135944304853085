import AxiosInstance from "./AxiosInstance";

export const getMine = async (Logger, planetId) => {

    try {
        const result = await AxiosInstance(Logger).get('/api/mine/' + planetId);
        return result

    } catch (error) {
        console.error("IMPOSSIBLE DE GENERER LES MINES DE LA PLANETE:", error);
        return "IMPOSSIBLE DE GENERER LES MINES DE LA PLANETE";
    }
}


export const putMine = async (Logger, planetId, data) => {

    try {
        const result = await AxiosInstance(Logger).put('/api/mine/' + planetId, data);
        return result

    } catch (error) {
        console.error("IMPOSSIBLE D'UPGRADE LES MINES DE LA PLANETE:", error);
        return "IMPOSSIBLE D'UPGRADE LES MINES DE LA PLANETE";
    }
}
