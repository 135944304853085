import AxiosInstance from "./AxiosInstance";

export const getPlanet = async (Logger, planetId) => {

    try {
        const result = await AxiosInstance(Logger).get('/api/planet/' + planetId);
        // console.log("RESULT ===", result)
        return result

    } catch (error) {
        console.error("IMPOSSIBLE DE GENERER LA PLANETE:", error);
        return "IMPOSSIBLE DE GENERER LA PLANETE";
    }
}
