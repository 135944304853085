import React, { useState } from 'react';
import {BarLoader} from "react-spinners";

const BuyUnits = ({ units, setUnits, onClick, item, disabled }) => {

    const handleChange = (e) => {
        setUnits(e.target.value);
    };

    return (
        <div className="buy-units-container">
            <input
                type="number"
                value={units}
                onChange={handleChange}
                className="units-input"
                min="1"
            />
            <button
                onClick={() => onClick(item.name, item.id, units)}
                className={disabled ? "buy-button" : "buy-button-disabled"}
                disabled={disabled}
            >
                {disabled ?
                    <BarLoader color={"#fff"} loading={disabled} size={50} />
                    : "Acheter"}
            </button>

        </div>
    );
};

export default BuyUnits;
