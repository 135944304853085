
export const CalculateTimer = async (remainingTime, item, upgrade) => {

    if (remainingTime !== null && remainingTime <= 0) {
        if (item && item.timeStart !== null && item.timeEnd !== null) {
            const now = Date.now();

            if (item.timeEnd * 1000 <= now) {
                console.log("ITEM DANS CalculateTimer ===", item);
                await upgrade(item.id);
            }
        } else {
            console.log("Données de l'item non disponibles ou invalides");
        }
    }
};

export const checkTimer = (remainingTime, item, setRemainingTime) => {

        if (!item || item.timeStart === null || item.timeEnd === null) {
            setRemainingTime(null);
            return;
        }

        const timeEnd = item.timeEnd * 1000;  // Convertir les timestamps de secondes en millisecondes

        // Calculer le temps restant en secondes
        const calculateRemainingTime = () => {
            const timeLeft = (timeEnd - Date.now()) / 1000; // Convertir en secondes
            if (timeLeft <= 0) {
                setRemainingTime(0);
            } else {
                setRemainingTime(timeLeft);
            }
        };

        // Initialiser le temps restant
        calculateRemainingTime();

        // Mettre à jour le temps restant toutes les secondes
        const interval = setInterval(() => {
            calculateRemainingTime();
        }, 1000);

        // Nettoyer l'intervalle lorsque le composant est démonté
        return () => clearInterval(interval);
}


export const formatTime = (time) => { // Fonction pour formater le temps en minutes et secondes
    if (time === null) return "Pas de recherche en cours";
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}m ${seconds}s`;
};
