import React, {useEffect, useState} from 'react';
import {closeGate, openGate} from "../../Api/Gate";

const Gate = ({ gate, planetSelected, Logger, reload, gateTarget }) => {

    const [inputValue, setInputValue] = useState('DGHNXC3');
    const [loading, setLoading] = useState(false);
    const optionData = [
        { id: 0, name: "Couper le vortex" },
        { id: 1, name: "Coloniser la planète" },
        { id: 2, name: "Transférer des unités" },
        { id: 3, name: "Transférer de la population" },
        { id: 4, name: "Transférer des ressources" },
        { id: 5, name: "Attaquer la planète" },
    ]

    useEffect(() => {
        if (gateTarget && gateTarget.user) {
            console.log("USE EFFECT ===", gateTarget)
        }
    }, [gateTarget])

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleSubmit = async () => {
        setLoading(true);
        const result = await openGate(Logger, planetSelected.id, inputValue);
        console.log("RESULT ===", result)
        if (result.message === "Ouverture du vortex") {
            reload();
            setLoading(false);
        } else {
            console.log("ERROR ===", result)
            setLoading(false);
        }
    };

    if (!gate && !planetSelected) {
        return null;
    }

    const selectOption = async (option) => {
        setLoading(true);
        if (option.id === 0) {
            console.log("CLOSE GATE ===", planetSelected.id)
            const result = await closeGate(Logger, planetSelected.id)
            console.log("RESULT ===", result)
            if (result.message === "Fermeture du vortex" || result.message === "Couper le vortex") {
                reload();
                setLoading(false);
            }
        } else {
            setLoading(false);
        }
        console.log("SELECTED OPTION ===", option)

    }

    const displayCloseGate = () => {
        return (
            <div>
                <input
                    type="text"
                    value={inputValue}
                    onChange={handleInputChange}
                    placeholder="Enter a value"
                />
                <button onClick={handleSubmit} disabled={loading}>
                    Valider
                </button>
            </div>
        )
    }

    const displayOpenGate = () => {
        return (
            <div style={{ display: "flex", flexDirection: "column", width: "30%", backgroundColor: "orange" }}>

                <button onClick={() => selectOption(optionData[0])} disabled={loading}>
                    {optionData[0].name}
                </button>
                {gateTarget && gateTarget.user && optionData.slice(2, 6).map((option, index) => (
                    <button key={index} onClick={() => selectOption(option)} disabled={loading}>
                        {option.name}
                    </button>
                ))}
                {gateTarget && !gateTarget.user && optionData.slice(1, 2).map((option, index) => (
                    <button key={index} onClick={() => selectOption(option)} disabled={loading}>
                        {option.name}
                    </button>
                ))}
            </div>

        )
    }

    return (
        <div className="containerGate">
            <div className={!gate.active ? "gateImageClose" : "gateImageOpen"}>
                {gate.active ? displayOpenGate() : displayCloseGate()}
            </div>
        </div>
    );
};

export default Gate;
