import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Notifications from "../../Components/Utilities/Notifications";
import {getResource} from "../../Api/Resource";
import {putPopulation} from "../../Api/Population";
import {Loader} from "../../Components/Utilities/Utilities";

const PopulationScreen = ({ Logger, population, resources, planetSelected, setUpdatedResources }) => {

    const [message, setMessage] = useState("");
    const [success, setSuccess] = useState(true);
    const [populationData, setPopulationData] = useState(null);
    const [inputs, setInputs] = useState({
        worker: '',
        slave: '',
        warrior: '',
        civil: '',
        scientist: ''
    });

    useEffect(() => {
        if (population) {
            setPopulationData(population);
        }
    }, [population]);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setInputs({
            ...inputs,
            [name]: value
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        // Calculer la somme des rôles demandés
        const totalRequested = Object.keys(inputs).reduce((sum, key) => {
            return sum + (Number(inputs[key]) || 0);
        }, 0);

        // Vérifier que resources et populationData sont définis
        if (!resources || !populationData) {
            console.error('Resources or Population data is missing');
            return;
        }

        // Calculer la population totale disponible à partir de resources
        const totalPopulationAvailable = resources.population;

        // console.log("Total Requested:", totalRequested);
        // console.log("Total Population Available:", totalPopulationAvailable);

        // Vérifier si la somme des rôles demandés est inférieure ou égale à la population totale disponible
        const enoughPopulation = totalRequested <= totalPopulationAvailable;

        if (enoughPopulation) {
            const resUpdate = await getResource(Logger, planetSelected.id);
            if (resUpdate) {
                const resPut = await putPopulation(Logger, planetSelected.id, inputs);
                if (resPut) {
                    console.log("result", resPut)
                    setSuccess(true);
                    setPopulationData(resPut);
                    setInputs({
                        worker: '',
                        slave: '',
                        warrior: '',
                        civil: '',
                        scientist: ''
                    });
                    setUpdatedResources(true);
                } else {
                    console.error('Erreur lors de la mise à jour de la population');
                    ErrorMessage("Erreur lors de la mise à jour de la population");
                }
            } else {
                console.error('Erreur lors de la mise à jour de la population');
                ErrorMessage("Erreur lors de la mise à jour de la population");
            }

        } else {
            console.log('Pas assez de population disponible');
            ErrorMessage("Pas assez de population disponible");
        }
    };

    const ErrorMessage = (message) => {
        setMessage(message);
        setSuccess(false);
    }

    if (!populationData || !resources) {
        return <Loader />
    }

    return (
        <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
            <div style={{ display: "flex", backgroundColor: "var(--bluePrimary)", justifyContent: "center" }}>
                <h3 style={{ color: "white" }}>Production: {population.populationProduction + " / Heures"}</h3>
            </div>

            <div className="population-container">
                <div className="population-details">
                    <h3 className="population-title">Population Details</h3>
                    <table className="population-table">
                        <tbody>
                        {Object.keys(populationData).map((key) => {
                            if (key !== 'id' && key !== 'populationProduction') {
                                return (
                                    <tr key={key}>
                                        <td className="population-key">{key.charAt(0).toUpperCase() + key.slice(1)}:</td>
                                        <td className="population-value">{populationData[key]}</td>
                                    </tr>
                                );
                            }
                            return null;
                        })}
                        </tbody>
                    </table>
                </div>
                <div className="population-form">
                    <h3 className="population-title">Assign Population Roles</h3>
                    <form onSubmit={handleSubmit}>
                        {Object.keys(inputs).map((key) => (
                            <div key={key} className="input-group">
                                <input
                                    type="number"
                                    id={key}
                                    name={key}
                                    value={inputs[key]}
                                    onChange={handleInputChange}
                                    className="input-field"
                                    min="0"
                                />
                            </div>
                        ))}
                        <button type="submit" className="submit-button">Submit</button>
                    </form>
                </div>
            </div>
            <Notifications
                message={message}
                success={success}
            />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        Logger: state.Logger
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: (action) => {
            dispatch(action);
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PopulationScreen);
