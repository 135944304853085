import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {getTechnology} from "../../Api/Technology";
import TechnologyCard from "../../Components/Technology/TechnologyCard";
import {Loader} from "../../Components/Utilities/Utilities";

const TechnologyScreen = ({ Logger, user, resources, population, setUpdatedResources }) => {

    const [technology, setTechnology] = useState([]);
    const [disabledGlobal, setDisabledGlobal] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        (async () => {
            if (user && user.id) {
                const res = await getTechnology(Logger, user.id);
                console.log("EFFECT TECHNOLOGY", res)
                setTechnology(res);
            }
        })();
    }, [Logger, user, resources,population]);

    useEffect( () => {
        if (loading) {
            setDisabledGlobal(true);
        } else {
            setDisabledGlobal(false);
        }
    }, [loading]);



    useEffect(() => {
        const anyBuildingInProgress = technology.some(building => building.technologyInProgress === true);
        setDisabledGlobal(anyBuildingInProgress);
    }, [technology]);

    const reload = async () => {
        setUpdatedResources(true);
    }


    if (!technology || !user || !resources) {
        return <Loader />
    }

    return (
        <div className="containerTechnologyScreen">
            {technology && Array.isArray(technology) && technology.map((item, index) => (
                <TechnologyCard
                    key={index}
                    item={item}
                    Logger={Logger}
                    disabledGlobal={disabledGlobal}
                    setLoading={setLoading}
                    resources={resources}
                    loading={loading}
                    population={population}
                    reload={reload}
                />
            ))}
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        Logger: state.Logger
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: (action) => {
            dispatch(action);
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TechnologyScreen);
