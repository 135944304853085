import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import BuildingCard from "../../Components/Building/BuildingCard";
import {getBuilding} from "../../Api/Building";

const BuildingScreen = ({ Logger, planetSelected, user, resources }) => {

    const [buildings, setBuildings] = useState([]);
    const [disabledGlobal, setDisabledGlobal] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect( () => {
        if (loading) {
            setDisabledGlobal(true);
        } else {
            setDisabledGlobal(false);
        }
    }, [loading]);

    useEffect(() => {
        (async () => {
            if (user && planetSelected) {
                const resBuilding = await getBuilding(Logger, planetSelected.id);
                setBuildings(resBuilding);
            }
        })();
    }, [user]);

    useEffect(() => {
        // Vérifiez si au moins un bâtiment a `buildingInProgress` à `true`
        const anyBuildingInProgress = buildings.some(building => building.buildingInProgress === true);

        // Mettre à jour l'état `disabled` en fonction de `buildingInProgress`
        setDisabledGlobal(anyBuildingInProgress);
    }, [buildings]);


    if (!buildings && !planetSelected) {
        return null;
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'row', flex: 1, justifyContent: "space-around", flexWrap: "wrap"}}>
            {buildings && Array.isArray(buildings) && buildings.map((item, index) => (
                <BuildingCard
                    key={index}
                    item={item}
                    Logger={Logger}
                    disabledGlobal={disabledGlobal}
                    setLoading={setLoading}
                    resources={resources}
                    loading={loading}
                />
            ))}
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        Logger: state.Logger
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: (action) => {
            dispatch(action);
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(BuildingScreen);
