import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import ButtonClassic from "../../Components/Utilities/ButtonClassic";
import {getGates, getUsers, resetGates} from "../../Api/Admin";
import UsersAdmin from "../../Components/Admin/UsersAdmin";
import Notifications from "../../Components/Utilities/Notifications";
import GatesAdmin from "../../Components/Admin/GatesAdmin";

const MembersScreen = ({ Logger }) => {


    const [message, setMessage] = useState(null);
    const [success, setSuccess] = useState(false);

    const [state, setState] = useState(0);
    const [users, setUsers] = useState(null);
    const [gates, setGates] = useState(null);
    const buttons = [
        { id: 1, name: 'Manage Users' },
        { id: 2, name: 'Manage Gates' },
        { id: 3, name: 'View Logs' },
        { id: 4, name: 'Settings' },
    ];

    useEffect( () => {
        (async () => {
            if (state === 1) {
                const res = await getUsers(Logger);
                setUsers(res)
            }
            if (state === 2) {
                const res = await getGates(Logger);
                setGates(res)
            }
        })()
    }, [state])

    const changeState = (id) => {
        setState(id)
    }

    const homeManager = () => {
        return (
            <div className="containerAdmin">
                {buttons.map((button) => (
                    <ButtonClassic
                        key={button.id}
                        onClick={() => changeState(button.id)}
                        name={button.name}
                    />
                ))}
            </div>
        )
    }

    return (
        <div className="containerAdmin">
            {state === 0 && homeManager()}
            {state === 1 &&
                <UsersAdmin
                    users={users}
                    changeState={changeState}
                />}
            {state === 2 &&
                <GatesAdmin
                    gates={gates}
                    Logger={Logger}
                    changeState={changeState}
                    setMessage={setMessage}
                    setSuccess={setSuccess}
                />}
            <Notifications
                message={message}
                success={success}
            />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        Logger: state.Logger
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: (action) => {
            dispatch(action);
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MembersScreen);
