import React, { useEffect, useState } from 'react';
import BuyButton from "../Utilities/BuyButton";
import {CalculateTimer, checkTimer, formatTime} from "../Utilities/CalculateTimer";
import {cancelTechnology, putTechnology} from "../../Api/Technology";
import {ErrorMessage} from "../Utilities/Error";
import Notifications from "../Utilities/Notifications";

const TechnologyCard = ({ item, Logger, disabledGlobal, setLoading, resources, population, loading, reload }) => {

    const [message, setMessage] = useState(null);
    const [remainingTime, setRemainingTime] = useState(null);
    const [name, setName] = useState(null);
    const [isDisabled, setIsDisabled] = useState(false);
    const [imagePath, setImagePath] = useState(null);
    const [success, setSuccess] = useState(true);

    useEffect(() => {
        if (item && item.image) {
            try {
                const imgPath = require(`./../../Assets/Technology/${item.image}`);
                setImagePath(imgPath);
            } catch (error) {
                console.log("ERROR IMAGE", error)
                // setImagePath(require('./../../Assets/default-image.png'));
            }
        }
    }, [item]);

    useEffect(() => {
        setIsDisabled(!checkRequirements());
    }, [resources, population, item]);

    useEffect(() => {
        (async () => {
            await CalculateTimer(remainingTime, item, updateCard);
        })();
    }, [remainingTime, item]);

    useEffect(() => {
        checkTimer(remainingTime, item, setRemainingTime);
    }, [item]);


    const checkRequirements = () => {
        const hasEnoughGold = resources.gold >= item.priceGold;
        const hasEnoughMetals = resources.metals >= item.priceMetals;
        const hasEnoughOrinia = resources.orinia >= item.priceOrinia;
        const hasEnoughOrinium = resources.orinium >= item.priceOrinium;
        const hasEnoughScientists = population.scientist >= item.scientist;
        // Fonction pour vérifier si les ressources et la population sont suffisantes
        return hasEnoughGold && hasEnoughMetals && hasEnoughOrinia && hasEnoughOrinium && hasEnoughScientists;
    };

    const updateCard = () => {
        window.location.reload();
    }

    const upgrade = async (idTechnology, name) => {
        console.log("idTechnology", idTechnology);
        console.log("name", name);
        setName(name);
        setIsDisabled(true);
        setLoading(true);
        const res = await putTechnology(Logger, Logger.userId, idTechnology);
        console.log("RES", res);
        if (res && res.message === "Développement lancé") {
            if (res.message.includes("Développement lancé") || res.message.includes("Développement terminé et niveau mis à jour")) {
                getLoading(res.message, true);
            }
        } else {
            getLoading("Impossible de lancer la recherche", false);
        }
    }

    const getLoading = (thisMessage, state) => {
        window.location.reload();
        ErrorMessage(thisMessage, state, setIsDisabled, setSuccess, setMessage);
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }

    const cancel = async (idTechnology) => {
        setIsDisabled(false);
        setLoading(true)

        const res = await cancelTechnology(Logger, Logger.userId, idTechnology);
        if (res) {
            getLoading("Recherche annulée", true);
        } else {
            getLoading("Impossible d'annuler la recherche", false);
        }
    };


    return (
        <div className="containerTechnology">
            <img src={imagePath} className={"imageTechnology"} alt={item.name}  />
            <div className={"containerTechnologyInfo"}>
                <div className="infoTechnology">{item.name}</div>
                <div className="infoTechnology">{"Niveau : " + item.level}</div>
                <div className="infoTechnology">{"Temps de construction : " + item.timeUpgrade}</div>
                <div className="infoTechnology">
                    {"Temps restant : " + (remainingTime !== null ? formatTime(remainingTime) : "Pas de recherche en cours")}
                </div>
                <div>{"Or : " + item.priceGold}</div>
                <div>{"Orinia : " + item.priceOrinia}</div>
                <div>{"Métaux : " + item.priceMetals}</div>
                <div>{"Orinium : " + item.priceOrinium}</div>
                <div>{"Scientifiques : " + item.scientist}</div>
            </div>
                <BuyButton
                    loading={loading}
                    disabledGlobal={disabledGlobal}
                    isDisabled={isDisabled}
                    item={item}
                    annulation={"Annuler la recherche"}
                    build={`Recherche de ${name === null ? "" : name} en cours`}
                    title={"Rechercher"}
                    upgrade={upgrade}
                    cancel={cancel}
                />
            <Notifications
                message={message}
                success={success}
            />
        </div>
    );
};

export default TechnologyCard;
