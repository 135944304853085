import AxiosInstance from "./AxiosInstance";

export const getPopulation = async (Logger, planetId) => {

    try {
        const result = await AxiosInstance(Logger).get('/api/population/' + planetId);
        return result

    } catch (error) {
        console.error("IMPOSSIBLE DE GENERER LES POPULATIONS PLANETE:", error);
        return "IMPOSSIBLE DE GENERER LES POPULATIONS PLANETE";
    }
}

export const putPopulation = async (Logger, planetId, data) => {

    try {

        const body = {
            worker: data.worker,
            slave: data.slave,
            warrior: data.warrior,
            civil: data.civil,
            scientist: data.scientist
        }

        const result = await AxiosInstance(Logger).put('/api/updatePopulation/' + planetId, body);
        return result

    } catch (error) {
        console.error("IMPOSSIBLE DE GENERER LES POPULATIONS PLANETE:", error);
        return "IMPOSSIBLE DE GENERER LES POPULATIONS PLANETE";
    }
}
