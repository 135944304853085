import React, { useEffect, useState } from 'react';

const BlockWelcome = ({ title, description, styleComponent = {}, titleStyle = {}, descriptionStyle = {}, icon = false }) => {

    const social = () => {
        window.open("https://www.youtube.com/@expeditionwars5515", "_blank");
    };

    return (
        <div className="containerBlockWelcome" style={styleComponent}>
            <div className="containerBlockWelcome-title" style={titleStyle}>
                {title}
            </div>
            {!icon && (
                <div
                    className="containerBlockWelcome-description"
                    style={descriptionStyle}
                    dangerouslySetInnerHTML={{ __html: description }}
                />
            )}
            {icon && (
                <div className="containerBlockWelcome-icons">
                    <div className="iconsSocial">
                        <a onClick={social} className="linkSocial"><i className="fa-brands fa-x-twitter"> </i></a>
                        <a onClick={social} className="linkSocial"><i className="fab fa-youtube"></i></a>
                    </div>
                </div>
            )}
        </div>
    );
};


export default BlockWelcome;

