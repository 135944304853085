import AxiosInstance from "./AxiosInstance";

export const getTechnology = async (Logger, idUser) => {

    try {
        const result = await AxiosInstance(Logger).get('/api/technologyUser/' + idUser);
        return result

    } catch (error) {
        console.error("IMPOSSIBLE DE GENERER LES TECHNOLOGIES DE LA PLANETE", error);
        return "IMPOSSIBLE DE GENERER LES TECHNOLOGIES DE LA PLANETE";
    }
}

export const putTechnology = async (Logger, idUser, idTechnology) => {

    try {
        const result = await AxiosInstance(Logger).put('/api/technologyUser/' + idUser + '/' + idTechnology);
        return result

    } catch (error) {
        console.error("IMPOSSIBLE DE D'AMELIORER LA TECHNOLOGIE", error);
        return "IMPOSSIBLE DE D'AMELIORER LA TECHNOLOGIE";
    }
}

export const cancelTechnology = async (Logger, idUser, idTechnology) => {

    try {
        const result = await AxiosInstance(Logger).put('/api/cancelTechnologyUser/' + idUser + '/' + idTechnology);
        return result

    } catch (error) {
        console.error("IMPOSSIBLE DE CANCEL LA TECHNOLOGIE", error);
        return "IMPOSSIBLE DE CANCEL LA TECHNOLOGIE";
    }
}

