import React, { useEffect, useState } from 'react';
import BlockWelcome from "./BlockWelcome";

const WelcomeBody = ({  }) => {

    const description = "Un jeu de stratégie en temps réel où vous devrez gérer vos ressources, vos armées et vos planètes pour devenir le maître de la galaxie."

    return (
        <div className="containerWelcomeBody">
            <div className={"containerWelcomeBodyLeft"}>
                <BlockWelcome
                    title={"Bienvenue sur Expedition-Wars"}
                    description={description}
                    styleComponent={{
                        marginBottom: "20px"
                    }}
                />
                <BlockWelcome
                    title={"Avancement du développement:"}
                    titleStyle={{
                        color: "var(--bluePrimary)",
                        textAlign: "left",
                        paddingLeft: "20px"
                    }}
                    descriptionStyle={{
                        padding: "20px",
                        textAlign: "left",
                        justifyContent: "flex-start"
                    }}
                    description={
                        "La nouvelle version du jeu est en cours de développement.<br /><br />" +
                        "Taches Terminées:<br /><br />" +
                        "Inscription et connexion.<br />" +
                        "Affichage des ressources en temps réel.<br />" +
                        "Liste des fakes batiments et technologies, avec système d'achat & d'annulation, de notifications, gestion du temps et des niveaux.<br />" +
                        "Une petite galaxie.<br />" +
                        "La gestion de la population, et la production de la population.<br />" +

                        "Mise à jours Septembre 2024 : <br /><br />" +
                        "Gestion de la connexion entre planète et de la porte <br />" +
                        "Cration des unités de combat <br />" +
                        "Gestion de l'attaque terrestre <br />" +
                        "Création de la partie administration sur les éléments déjà présent <br />"


                    }
                />
            </div>
            <div className={"containerWelcomeBodyRight"}>
                <BlockWelcome
                    title={"Social"}
                    description={""}
                    styleComponent={{
                        width: "80%",
                        alignSelf: "center"
                    }}
                    icon={true}
                />
            </div>
        </div>
    );
};


export default WelcomeBody;
