import React, {useEffect, useRef, useState} from "react";
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import WelcomeScreen from "./Screens/Welcome/WelcomeScreen";
import {connect, useDispatch} from "react-redux";
import HomeScreen from "./Screens/Home/HomeScreen";
import ControlsScreen from "./Screens/Home/ControlScreen";
import MembersScreen from "./Screens/Administration/MembersScreen";
import BuildingScreen from "./Screens/Building/BuildingScreen";
import PopulationScreen from "./Screens/Population/PopulationScreen";
import TechnologyScreen from "./Screens/Technology/TechnologyScreen";
import BarrackScreen from "./Screens/Barrack/BarrackScreen";
import GateScreen from "./Screens/Gate/GateScreen";
import MineScreen from "./Screens/Mine/MineScreen";
import ProductionScreen from "./Screens/Production/ProductionScreen";

function MyWebSite (props) {

    const isAuthenticated = !!props.Logger.user && !!props.Logger.token && (props.Logger.userId !== null);

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={isAuthenticated ? <Navigate to="/controls" /> : <WelcomeScreen />} />
                <Route path="*" element={isAuthenticated ? <Navigate to="/controls" /> : <WelcomeScreen />} />
                <Route path="/controls" element={isAuthenticated ? <HomeScreen><ControlsScreen /></HomeScreen> : <Navigate to="/" />} />
                <Route path="/population" element={isAuthenticated ? <HomeScreen><PopulationScreen/></HomeScreen> : <Navigate to="/" />} />
                <Route path="/building" element={isAuthenticated ? <HomeScreen><BuildingScreen /></HomeScreen> : <Navigate to="/" />} />
                <Route path="/technology" element={isAuthenticated ? <HomeScreen><TechnologyScreen /></HomeScreen> : <Navigate to="/" />} />
                <Route path="/barrack" element={isAuthenticated ? <HomeScreen><BarrackScreen /></HomeScreen> : <Navigate to="/" />} />
                <Route path="/gate" element={isAuthenticated ? <HomeScreen><GateScreen /></HomeScreen> : <Navigate to="/" />} />
                <Route path="/mine" element={isAuthenticated ? <HomeScreen><MineScreen /></HomeScreen> : <Navigate to="/" />} />
                <Route path="/production" element={isAuthenticated ? <HomeScreen><ProductionScreen /></HomeScreen> : <Navigate to="/" />} />
                <Route path="/members" element={isAuthenticated ? <HomeScreen><MembersScreen/></HomeScreen> : <Navigate to="/" />} />
            </Routes>
        </BrowserRouter>
    );

}


const mapStateToProps = (state) => {
    return state
}
const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: (action) => { dispatch(action) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyWebSite);
