import React, { useEffect, useState } from 'react';

const HeaderResource = ({ resources, mine, population, updated }) => {

    const [updatedResources, setUpdatedResources] = useState(resources);
    const [initialLoad, setInitialLoad] = useState(true);

    useEffect(() => {
        if (resources && mine) {
            // Met à jour les ressources et le timestamp de dernière mise à jour
            if (initialLoad) {
                setUpdatedResources(resources);
                setInitialLoad(false); // Réinitialiser le drapeau après le chargement initial
            }
        }
    }, [resources, mine, initialLoad, population]);

    useEffect( () => {
            setInitialLoad(true);
    }, [updated]);

    useEffect(() => {
        if (!mine || !population || !resources) return; // Ne pas calculer si `mine` ou `population` ne sont pas encore disponibles

        const calculateUpdatedResources = () => {
            // Assurer que mine a des valeurs valides
            const workerMetals = mine.workerMetals || 0;
            const slaveMetals = mine.slaveMetals || 0;
            const workerGold = mine.workerGold || 0;
            const slaveGold = mine.slaveGold || 0;
            const workerOrinia = mine.workerOrinia || 0;
            const slaveOrinia = mine.slaveOrinia || 0;
            const workerOrinium = mine.workerOrinium || 0;
            const slaveOrinium = mine.slaveOrinium || 0;
            const populationProduction = population.populationProduction || 0;

            // Production par seconde
            const productionPerSecond = {
                metals: (workerMetals + slaveMetals) / 3600,
                gold: (workerGold + slaveGold) / 3600,
                orinia: (workerOrinia + slaveOrinia) / 3600,
                orinium: (workerOrinium + slaveOrinium) / 3600,
                population: (populationProduction) / 3600
            };

            // Calculer les nouvelles valeurs de ressources
            const newResources = {
                metals: (updatedResources.metals || 0) + productionPerSecond.metals,
                gold: (updatedResources.gold || 0) + productionPerSecond.gold,
                orinia: (updatedResources.orinia || 0) + productionPerSecond.orinia,
                orinium: (updatedResources.orinium || 0) + productionPerSecond.orinium,
                population: (updatedResources.population || 0) + productionPerSecond.population,
            };

            setUpdatedResources(newResources);
        };

        // Calculer les ressources mises à jour toutes les secondes
        const intervalId = setInterval(calculateUpdatedResources, 1000);

        // Nettoyer l'intervalle lors du démontage du composant
        return () => clearInterval(intervalId);
    }, [updatedResources, mine, population]);


    const resourcePairs = [
        [
            { label: "Métaux", key: "metals" },
            { label: "Or", key: "gold" },
            { label: "Population", key: "population" },
        ],
        [
            { label: "Orinia", key: "orinia" },
            { label: "Orinium", key: "orinium" },
            { label: "Prod. Pop", key: "populationProduction" },
        ],
    ];

    const populationGroups  = [
        [
            { label: "Guerriers", key: "warrior" },
            { label: "Scientifiques", key: "scientist" },
            { label: "Esclaves", key: "slave" },
        ],
        [
            { label: "Civils", key: "civil" },
            { label: "Ouvriers", key: "worker" },
            { label: "Pilotes", key: "pilot" },
        ],
    ];

    const formatNumber = (number) => {
        return number.toLocaleString(); // Ajoute les séparateurs de milliers
    };

    return (
        <div className={"containerHeaderResource"}>
            <div className={"containerResourceLeft"}>
                {resourcePairs.map((pair, index) => (
                    <div className={"blockResource"} key={index}>
                        {pair.map((resource) => (
                            <div className={"resourceClass"} key={resource.key}>
                                {`${resource.label} : ${
                                    resource.key === "populationProduction"
                                        ? formatNumber(Math.round(population[resource.key] || 0))
                                        : formatNumber(Math.round(updatedResources[resource.key] || 0))
                                }`}
                            </div>
                        ))}
                    </div>
                ))}
            </div>
            <div className={"containerResourceCenter"}>
                <div className={"circleEnergy"}>
                    <div className={"energyClass"}>{"Energie : " + Math.round(resources.energy)}</div>
                </div>
            </div>
            <div className={"containerResourceRight"}>
                {populationGroups.map((group, index) => (
                    <div className={"blockResource"} key={index}>
                        {group.map((resource) => (
                            <div className={"resourceClass"} key={resource.key}>
                                {`${resource.label} : ${formatNumber(Math.round(population[resource.key]))}`}
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default HeaderResource;
