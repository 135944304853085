import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Notifications from "../../Components/Utilities/Notifications";
import {getGate} from "../../Api/Gate";
import Gate from "../../Components/Gate/Gate";

const GateScreen = ({ Logger, user, resources, population, planetSelected, setUpdatedResources }) => {

    const [gate, setGate] = useState([]);
    const [reload, setReload] = useState(false);
    const [message, setMessage] = useState(null);
    const [success, setSuccess] = useState(false);
    const [gateTarget, setGateTarget] = useState(null);

    useEffect(() => {
        (async () => {
            if (user && user.id && planetSelected && planetSelected.id) {
                const res = await getGate(Logger, planetSelected.id);
                setGate(res);
                console.log("RES GATE ===", res)
            }
        })();
    }, [planetSelected, Logger, reload]);

    useEffect( () => {
        (async () => {
            if (gate && gate.galaxyConnected) {
                const res = await getGate(Logger, gate.galaxyConnected);
                setGateTarget(res);
                console.log("RES setGateTarget GATE ===", res)
            }
        })();
    }, [gate])

    const openGate = async () => {
        setReload(!reload);
    }

    if (!gate && !user) {
        return null;
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
            <Gate
                gate={gate}
                planetSelected={planetSelected}
                Logger={Logger}
                reload={openGate}
                gateTarget={gateTarget}
            />
            <Notifications
                message={message}
                success={success}
            />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        Logger: state.Logger
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: (action) => {
            dispatch(action);
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(GateScreen);
