import React, {useState} from 'react';
import {BarLoader} from "react-spinners";

const ButtonClassic = ({ placeholder, onClick, name, disabled= false, typeButton = true, inputStyle = {}, inputSubmitStyle = {} }) => {

    const [inputValue, setInputValue] = useState("");

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };

    const handleSubmit = () => {
        if (onClick) {
            onClick(inputValue);
        }
    };

    const getButton = () => {
        return (
            <button className="buttonClassic" onClick={onClick}>
                {name}
            </button>
        );
    };

    const getInput = () => {
        return (
            <div className="inputWrapper">
                <input
                    type="number"
                    className="inputNumber"
                    placeholder={placeholder}
                    style={inputStyle}
                    value={inputValue}
                    onChange={handleInputChange}
                />
                {!disabled && <input
                    type="submit"
                    value={name}
                    className="buttonClassic"
                    onClick={handleSubmit}
                    style={inputSubmitStyle}
                />}
                {disabled &&
                    <div className="buttonClassic" style={{display: "flex", justifyContent: "center"}}>
                        <BarLoader color={"var(--white)"} loading={disabled} size={50} />
                    </div>
                }
            </div>
        );
    };

    return (
        <div>
            {typeButton ? getButton() : getInput()}
        </div>
    );
};

export default ButtonClassic;
