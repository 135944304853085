import React from 'react';
import ButtonClassic from "../Utilities/ButtonClassic";
import {resetGates} from "../../Api/Admin";
import TableAdmin from "./TableAdmin";

const GatesAdmin = ({ gates, Logger, changeState, setMessage, setSuccess }) => {

    const gateColumns = [
        { header: 'ID', accessor: 'id' },
        { header: 'Galaxy ID', accessor: 'galaxy' },
        { header: 'Active', accessor: 'active' },
        { header: 'Timestamp Open', accessor: 'timestampOpen' },
        { header: 'Timestamp Close', accessor: 'timestampClose' },
        { header: 'Exploration', accessor: 'exploration' },
        { header: 'Protected', accessor: 'protected' },
        { header: 'Opened By User ID', accessor: 'openedByUserId' },
        { header: 'Galaxy Connected ID', accessor: 'galaxyConnected' }
    ];

    const groups = (message, success, notification) => {
        setMessage(message)
        setSuccess(success)
    }

    return (
        <div className={"AdminUsersContainer"}>
            <h1>Manage Gates</h1>
            <div> Réinitialise la table gate_galaxy aux valeurs null</div>
            <ButtonClassic
                onClick={async () => {
                    const result = await resetGates(Logger)
                    if (result.message === "Toutes les portes ont été réinitialisées avec succès") {
                        groups(result.message, true, true)
                    } else {
                        groups("Erreur de la réinitialisation", false, true)
                    }
                }}
                name={"Réinitialiser les portes"}
            />
            {gates && <TableAdmin
                data={gates}
                columns={gateColumns}
            />}
            <ButtonClassic
                onClick={() => changeState(0)}
                name={"Retour"}
            />
        </div>
    );
};

export default GatesAdmin;



