import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {getBarrack, getCancelBarrack} from "../../Api/Barrack";
import BarrackCard from "../../Components/BarrackCard";
import Notifications from "../../Components/Utilities/Notifications";
import {ErrorMessage} from "../../Components/Utilities/Error";
import {formatTime} from "../../Components/Utilities/CalculateTimer";

const BarrackScreen = ({ Logger, user, resources, population, planetSelected, setUpdatedResources }) => {

    const [barrack, setBarrack] = useState([]);
    const [disabled, setDisabled] = useState(false);
    const [reload, setReload] = useState(false);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState(null);
    const [success, setSuccess] = useState(false);


    useEffect(() => {
        (async () => {
            if (user && user.id) {
                const res = await getBarrack(Logger, planetSelected.id);
                setBarrack(res);
                setLoading(false);
            }
        })();
    }, [Logger, user, reload]);

    useEffect(() => {
        // Function to update remaining times every second
        const updateTimes = () => {
            setBarrack(prevBarrack => prevBarrack.map(item => {
                const now = Math.floor(Date.now() / 1000); // Current time in seconds
                return {
                    ...item,
                    progress: item.progress.map(progressItem => {
                        const remainingTime = Math.max(0, progressItem.timeEnd - now);
                        if (remainingTime === 0) {
                            getReload()
                        }
                        return {
                            ...progressItem,
                            remainingTime
                        };
                    })
                };
            }));
        };

        updateTimes(); // Initial update
        const interval = setInterval(updateTimes, 1000); // Update every second
        return () => clearInterval(interval); // Cleanup interval on component unmount
    }, [loading]);

    const getReload = async () => {
        setUpdatedResources(true);
        setReload(!reload);
    };

    const cancelFormation = async (unitTypeId, planetId, barrackGalaxyId) => {
        if (!disabled) {
            setDisabled(true)
            const result = await getCancelBarrack(Logger, planetId, unitTypeId, barrackGalaxyId);

            setMessage(result.message);
            if (result.message === "Construction annulée et ressources remboursées.") {
                ErrorMessage(result.message, true, setDisabled, setSuccess, setMessage);
                getReload();
            } else {
                ErrorMessage("Impossible d'annuler la formation de l'unité.", false, setDisabled, setSuccess, setMessage);
            }
        }
    }

    if (!barrack.length && !user) {
        return null;
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
            <div className={"containerTimeBarrack"}>
                <h1 style={{ color: 'var(--white)', fontSize: 20, marginBottom: 20 }}>Liste de construction</h1>
                <div className="infoBarrack">
                    {barrack.map(barrackItem => (
                        <div key={barrackItem.id}>
                            {barrackItem.progress.map((progressItem, index) => (
                                <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
                                    <div>
                                        {progressItem.units + " " + barrackItem.name + " en cours de formation : " + formatTime(progressItem.remainingTime)}
                                    </div>
                                    <div
                                        className={"cancelButtonBarrack"}
                                        onClick={
                                            () =>
                                                cancelFormation(progressItem.unitTypeId, progressItem.planetId, progressItem.barrackGalaxyId)
                                        }
                                    >
                                        X
                                    </div>
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', flex: 1, justifyContent: "space-around" }}>
                {barrack.map((item, index) => (
                    <BarrackCard
                        key={index}
                        item={item}
                        Logger={Logger}
                        resources={resources}
                        population={population}
                        getReload={getReload}
                        disabled={disabled}
                        setDisabled={setDisabled}
                        setMessage={setMessage}
                        setSuccess={setSuccess}
                    />
                ))}
            </div>
            <Notifications
                message={message}
                success={success}
            />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        Logger: state.Logger
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: (action) => {
            dispatch(action);
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(BarrackScreen);
