import React from 'react';

const TableAdmin = ({ columns, data }) => {

    console.log("DATA ===", data)

    return (
        <table className="AdminUsers">
            <thead>
            <tr>
                {columns.map((column, index) => (
                    <th key={index}>{column.header}</th>
                ))}
            </tr>
            </thead>
            <tbody>
            {data && data.map((item, rowIndex) => (
                <tr key={rowIndex}>
                    {columns.map((column, colIndex) => (
                        <td key={colIndex}>{item[column.accessor]}</td>
                    ))}
                </tr>
            ))}
            </tbody>
        </table>
    );
};

export default TableAdmin;
