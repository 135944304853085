import AxiosInstance from "./AxiosInstance";

export const getGate = async (Logger, planetId) => {

    try {

        const result = await AxiosInstance(Logger).get('/api/gate/' + planetId);
        return result

    } catch (error) {
        console.error("IMPOSSIBLE DE GENERER LES GATES:", error);
        return "IMPOSSIBLE DE GENERER LES GATES";
    }
}

export const openGate = async (Logger, planetId, address) => {
    try {
        const data = {
            address: address
        };

        const result = await AxiosInstance(Logger).put(`/api/openGate/` + planetId, data);
        return result;

    } catch (error) {
        console.error("IMPOSSIBLE D'OUVRIR LA PORTE:", error);
        return "IMPOSSIBLE D'OUVRIR LA PORTE";
    }
}

export const closeGate = async (Logger, planetId) => {
    try {
        const result = await AxiosInstance(Logger).put(`/api/closeGate/` + planetId);
        return result;

    } catch (error) {
        console.error("IMPOSSIBLE DE FERMER LA PORTE:", error);
        return "IMPOSSIBLE DE FERMER LA PORTE";
    }
}
