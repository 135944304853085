import AxiosInstance from "./AxiosInstance";

export const getBarrack = async (Logger, planetId) => {

    try {
        const result = await AxiosInstance(Logger).get('/api/barrack/' + planetId);
        // console.log("RESULT BARRACK ===", result)
        return result

    } catch (error) {
        console.error("IMPOSSIBLE DE GENERER LES BARRACK:", error);
        return "IMPOSSIBLE DE GENERER LES BARRACK";
    }
}


export const getUpgradeBarrack = async (Logger, planetId, idBarrack, data) => {

    // console.log("PLANET ID ===", planetId)
    try {

        const body = {
            "unitCount": data.units
        }


        const result = await AxiosInstance(Logger).put('/api/updateBarrack/' + planetId + '/' + idBarrack, body);
        // console.log("RESULT BATIMENTS UPGRADE ===", result)
        return result

    } catch (error) {
        console.error("IMPOSSIBLE DE UPGRADE LES UNITEs:", error);
        return "IMPOSSIBLE DE UPGRADE LES UNITEs";
    }
}

export const getCancelBarrack = async (Logger, planetId, idTypeUnit, idBarrack) => {

    try {

        const result = await AxiosInstance(Logger).delete('/api/cancelBarrack/' + planetId + '/' + idBarrack + '/' + idTypeUnit);
        return result

    } catch (error) {
        console.error("IMPOSSIBLE DE CANCEL L'ACHAT D'UNITE", error);
        return "IMPOSSIBLE DE CANCEL L'ACHAT D'UNITE";
    }
}
