import React, {useEffect, useRef, useState} from 'react';
import HeaderResource from "../Resource/HeaderResource";
import {getResource} from "../../Api/Resource";
import {getPopulation} from "../../Api/Population";

const BlockBody = ({ Logger, children, title, user, planetSelected, resources, population, mine }) => {

    const [newResources, setNewResources] = useState(resources);
    const [newPopulation, setNewPopulation] = useState(population);
    const [updatedResources, setUpdatedResources] = useState(false);
    const [updated, setUpdated] = useState(false);

    useEffect(() => {
        (async () => {
           if (updatedResources) {
               const resultResources = await getResource(Logger, planetSelected.id);
               const resultPopulation = await getPopulation(Logger, planetSelected.id);
                setNewPopulation(resultPopulation);
               setNewResources(resultResources);
               setUpdated(!updated);
               setUpdatedResources(false) //Je reinitialise
           }
        })();
    }, [updatedResources]);

    return (
        <div style={{ flex: 1, display: "flex", flexDirection: "column"}}>
                <div className={"containerResource"}>
                    {planetSelected && planetSelected.address && resources &&
                        <HeaderResource
                            resources={newResources}
                            mine={mine}
                            population={newPopulation}
                            updated={updated}
                        />
                    }
                </div>
            {/*{planetSelected && planetSelected.address &&*/}
            {/*    <div style={{...texTitleStyle}}>{"Planète : " + planetSelected.address }</div>}*/}
            {user && planetSelected && children && population && resources &&
                <div style={{ alignItems: "center", display: "flex", flex: 1 }}>
                    {React.cloneElement(children, { user, planetSelected, resources, population, setUpdatedResources })}
                </div>}
        </div>
    );
};


export default BlockBody;
