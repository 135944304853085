import AxiosInstance from "./AxiosInstance";

export const getResource = async (Logger, planetId) => {

    try {
        const result = await AxiosInstance(Logger).get('/api/resource/' + planetId);
        return result

    } catch (error) {
        console.error("IMPOSSIBLE DE GENERER LES RESSOURCES PLANETE:", error);
        return "IMPOSSIBLE DE GENERER LES RESSOURCES PLANETE";
    }
}
