import React, { useEffect, useState } from 'react';
import '../../styles/Notifications.scss';

const Notifications = ({ message, success }) => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        if (message) {
            setIsVisible(true);
            const timer = setTimeout(() => {
                setIsVisible(false);
            }, 3000);

            return () => clearTimeout(timer);
        }
    }, [message, success]);

    if (!isVisible) return null;

    return (
        <div className={`notification ${success ? 'success' : 'error'}`}>
            <p className="message">{message}</p>
        </div>
    );
};

export default Notifications;
