import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Notifications from "../../Components/Utilities/Notifications";
import {getMine, putMine} from "../../Api/Mine";
import MineCard from "../../Components/Mine/MineCard";
import {getMineName} from "../../Components/Utilities/Switch";
import {ErrorMessage} from "../../Components/Utilities/Error";
import {Loader} from "../../Components/Utilities/Utilities";

const MineScreen = ({ Logger, population, resources, planetSelected, setUpdatedResources }) => {

    const [message, setMessage] = useState("");
    const [success, setSuccess] = useState(true);
    const [mines, setMines] = useState(null);
    const [disabled, setDisabled] = useState(false);

    useEffect( () => {
        (async () => {
            if (population && planetSelected && planetSelected.id) {
                const res = await getMine(Logger, planetSelected.id);
                setMines(res);
            }
        })();
    }, [population, resources, planetSelected, disabled])

    const addMine = async (mine, state) => {
        const isWorkerType = getMineName(mine) === "worker";

        const data = {
            mineType: mine,
            workers: isWorkerType ? state : 0,
            slaves: isWorkerType ? 0 : state
        };

        await updateMine(data);
    };

    const updateMine = async (data) => {
        setDisabled(true);
        const result = await putMine(Logger, planetSelected.id, data);
        if (result.message === "Mine mise à jour avec succès") {
            setUpdatedResources(true);
            ErrorMessage(result.message, true, setDisabled, setSuccess, setMessage);
        } else {
            ErrorMessage(result, false, setDisabled, setSuccess, setMessage);
        }
    }




    if (!mines || !resources) {
        return <Loader />
    }


    return (
        <div className={"containerMineScreen"}>
            <div style={{display: "flex", flexDirection: "row"}}>
                {mines && Object.entries(mines).slice(0,4).map(([field, value], index) => (
                    <MineCard
                        key={index}
                        item={{ name: field, value: value }}
                        onClick={addMine}
                        disabled={disabled}
                    />
                ))}
            </div>
            <div style={{display: "flex", flexDirection: "row"}}>
                {mines && Object.entries(mines).slice(4,8).map(([field, value], index) => (
                    <MineCard
                        key={index}
                        item={{ name: field, value: value }}
                        onClick={addMine}
                        disabled={disabled}
                    />
                ))}
            </div>
            <Notifications
                message={message}
                success={success}
            />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        Logger: state.Logger
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: (action) => {
            dispatch(action);
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MineScreen);
