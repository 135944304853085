import React, { useState } from 'react';
import ButtonClassic from "../Utilities/ButtonClassic";
import {getName} from "../Utilities/Switch";
import {BarLoader} from "react-spinners";

const MineCard = ({ item, onClick, disabled }) => {

    return (
        <div className="containerMine">
            <div className="containerMineHeaderTitle">
                <div className="titleMine">{getName(item.name)}</div>
                <div className="numberMine">Nombre Actuel : {item.value}</div>
                <ButtonClassic
                    typeButton={false}
                    inputSubmitStyle={{ marginTop: 5}}
                    placeholder={"Ajouter ici"}
                    onClick={ (state) => {
                        if (!disabled) {
                            if (state > 0) {
                                onClick(item.name, state)
                            } else {
                                alert("Veuillez entrer un nombre valide")
                            }
                            }
                        }}
                    disabled={disabled}
                />
            </div>
        </div>
    );
};

export default MineCard;
