import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import BlockBody from "../../Components/Body/BlockBody";
import { logout } from "../../Redux/actions/authActions";
import BlockMenu from "../../Components/Menu/BlockMenu";
import { getUser } from "../../Api/User";
import {getPlanet} from "../../Api/Planet";
import {getResource} from "../../Api/Resource";
import {getMine} from "../../Api/Mine";
import {getPopulation} from "../../Api/Population";

const HomeScreen = ({ dispatch, Logger, children }) => {

    const [title, setTitle] = useState("Accueil");
    const [menus, setMenus] = useState([]);
    const [user, setUser] = useState({});
    const [planetSelected, setPlanetSelected] = useState(null)
    const [resources, setResources] = useState(null);
    const [mine, setMine] = useState(null);
    const [population, setPopulation] = useState(null);

    useEffect(() => {
        (async () => {
            const res = await getUser(Logger);
            setUser(res);
        })();
    }, [Logger]);

    useEffect( () => {
        (async () => {
            if (user && user.planetSelected && user.planetSelected.id) {
                const idPlanet = user.planetSelected.id;
                const res = await getPlanet(Logger, idPlanet)
                const resultResources = await getResource(Logger, idPlanet);
                const resultPopulation = await getPopulation(Logger, idPlanet);
                setResources(resultResources);
                setPlanetSelected(res)
                setPopulation(resultPopulation);
            }
        })()
    }, [Logger, user])

    useEffect( () => {
        (async () => {
            if (user && user.planetSelected && user.planetSelected.id && resources) {
                const idPlanet = user.planetSelected.id;
                const resultMine = await getMine(Logger, idPlanet);
                setMine(resultMine);
            }
        })()
    }, [resources])

    useEffect(() => {
        setMenus(() => {
            let updatedMenus = [
                {
                    title: 'JOUEURS',
                    menuItems: [
                        { link: '/controls', text: 'Accueil' },
                        { link: '/population', text: 'Population' },
                        { link: '/building', text: 'Batiments' },
                        { link: '/technology', text: 'Technologies' },
                        { link: '/barrack', text: 'Caserne' },
                        { link: '/gate', text: 'Portail Spatial' },
                        { link: '/mine', text: 'Mine' },
                        { link: '/production', text: 'Production' },
                        { link: '/', text: '' },
                        { link: '/logout', text: 'Déconnexion' },
                    ],
                },
            ];

            if (user.rank_user === 'admin') {
                updatedMenus.push({
                    title: 'ADMINISTRATION',
                    menuItems: [
                        { link: '/members', text: 'Gestion des Membres' },
                    ],
                });
            }

            return updatedMenus;
        });
    }, [user]);


    const handleLogout = () => { // PEUT RENVOYER SUR L'ACCUEIL MAIS PAS NECESSAIRE CAR GERER PAR NAVIGATION
        dispatch(logout());
    };

    const onClickMenu = (state) => {
        setTitle(state);
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100vh', backgroundColor: "var(--black)" }}>
            <div style={{ display: 'flex', flex: 1, backgroundColor: 'black', color: "white" }}>
                <div style={{ flexDirection: 'column', display: 'flex', paddingTop: "5%", width: 200, alignItems: "center" }}>
                    {menus.map((menu, index) => (
                        <BlockMenu
                            key={index}
                            title={menu.title}
                            menuItems={menu.menuItems}
                            handleLogout={handleLogout}
                            onClickMenu={onClickMenu}
                        />
                    ))}
                </div>
                {user && planetSelected &&
                    <BlockBody
                        children={children}
                        Logger={Logger}
                        title={title}
                        user={user}
                        planetSelected={planetSelected}
                        resources={resources}
                        mine={mine}
                        population={population}
                    />}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        Logger: state.Logger
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: (action) => {
            dispatch(action);
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeScreen);
