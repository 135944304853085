import React, { useEffect, useState } from 'react';
import {getUpgradeBarrack} from "../Api/Barrack";
import BuyUnits from "./Utilities/BuyUnits";
import {ErrorMessage} from "./Utilities/Error";

const BarrackCard = ({ item, Logger, resources, population, getReload, disabled, setDisabled, setMessage, setSuccess, setIsVisibleNotification }) => {

    const [imagePath, setImagePath] = useState(null);
    const [units, setUnits] = useState(0);

    useEffect(() => {
        if (item && item.image) {
            try {
                const imgPath = require(`./../../Assets/Barrack/${item.image}`);
                setImagePath(imgPath);
            } catch (error) {
                // console.log("ERROR IMAGE", error)
                // setImagePath(require('./../../Assets/default-image.png'));
            }
        }
    }, [item]);



    const checkRequirements = (unitsNumber) => {
        const hasEnoughGold = resources.gold >= item.priceGold * unitsNumber;
        const hasEnoughMetals = resources.metals >= item.priceMetals * unitsNumber;
        const hasEnoughOrinia = resources.orinia >= item.priceOrinia * unitsNumber;
        const hasEnoughOrinium = resources.orinium >= item.priceOrinium * unitsNumber;

        // Fonction pour vérifier si les ressources et la population sont suffisantes
        return hasEnoughGold && hasEnoughMetals && hasEnoughOrinia && hasEnoughOrinium;
    };

    const handleBuy = async (name, idBarrack, unitsNumber) => {
        if (unitsNumber > 0) {
            if (unitsNumber <= population.warrior && checkRequirements(unitsNumber)) {
                setDisabled(true);
                const res = await getUpgradeBarrack(Logger, item.idGalaxy, idBarrack, {units: unitsNumber});
                console.log("RES ===", res)
                ErrorMessage(res.message, true, setDisabled, setSuccess, setMessage);
                getReload(true);
            } else {
                ErrorMessage("Pas assez de guerriers ou de ressources", false, setDisabled, setSuccess, setMessage);
            }
        } else {
            ErrorMessage("Veuillez entrer un nombre d'unités valide", false, setDisabled, setSuccess, setMessage);
        }
    };


    return (
        <div className="containerBarrack">
            <img src={imagePath} className={"imageBarrack"} alt={item.name}  />
            <div className={"containerBarrackInfo"}>
                <div className="infoBarrack">{item.name}</div>
                <div className="infoBarrack">{"Temps de fabrication : " + item.timeUpgrade}</div>
                <div>{"Or : " + item.priceGold}</div>
                <div>{"Orinia : " + item.priceOrinia}</div>
                <div>{"Métaux : " + item.priceMetals}</div>
                <div>{"Orinium : " + item.priceOrinium}</div>
                <div></div>
                <div>{"Attaque:"}</div>
                <div>{"Défense:"}</div>
                <div>{"Unités possédés : " + item.unitsPossessed}</div>
                <div>{""}</div>
            </div>
            <BuyUnits
                item={item}
                units={units}
                setUnits={setUnits}
                onClick={handleBuy}
                disabled={disabled}
            />
        </div>
    );
};

export default BarrackCard;
