import React, { useEffect, useState } from 'react';
import {getCancelBuilding, getUpgradeBuilding} from "../../Api/Building";
import BuyButton from "../Utilities/BuyButton";
import {CalculateTimer, checkTimer, formatTime} from "../Utilities/CalculateTimer";

const BuildingCard = ({ item, Logger, disabledGlobal, setLoading, resources, loading }) => {

    const [message, setMessage] = useState(null);
    const [remainingTime, setRemainingTime] = useState(null);
    const [name, setName] = useState(null);
    const [isDisabled, setIsDisabled] = useState(false);

    const [imagePath, setImagePath] = useState(null);

    useEffect(() => {
        if (item && item.image) {
            try {
                const imgPath = require(`./../../Assets/Building/${item.image}`);
                setImagePath(imgPath);
            } catch (error) {
                console.log("ERROR IMAGE", error)
                // setImagePath(require('./../../Assets/default-image.png'));
            }
        }
    }, [item]);

    useEffect(() => {
        const checkResources = () => {
            if (resources) {
                const {
                    priceGold,
                    priceOrinia,
                    priceMetals,
                    priceOrinium,
                    priceEnergy
                } = item;

                const {
                    gold,
                    orinia,
                    metals,
                    orinium,
                    energy
                } = resources;

                const isResourceDisabled = priceGold > gold || priceOrinia > orinia || priceMetals > metals || priceOrinium > orinium || priceEnergy > energy;
                // console.log("isResourceDisabled ===", isResourceDisabled)
                setIsDisabled(isResourceDisabled);
            }
        };

        checkResources();
    }, [resources, item]);

    useEffect(() => {
        (async () => {
            await CalculateTimer(remainingTime, item, upgrade);
        })();
    }, [remainingTime, item]);

    useEffect(() => {
        checkTimer(remainingTime, item, setRemainingTime);
    }, [item]);


    const upgrade = async (idItem, nameItem) => {
        setName(nameItem);
        setIsDisabled(true);
        setLoading(true)
        const data = {
            priceGold: item.priceGold,
            priceOrinia: item.priceOrinia,
            priceMetals: item.priceMetals,
            priceOrinium: item.priceOrinium,
            priceEnergy: item.priceEnergy,
        }
        const res = await getUpgradeBuilding(Logger, item.idPlanet, idItem, data);
        if (res) {
            setMessage(res.message);
            if (res.message.includes("Construction lancée") || res.message.includes("Bâtiment mis à jour. Niveau augmenté")) {
                window.location.reload();
                setTimeout(() => {
                    setLoading(false);
                }, 1000);
            }
        } else {
            setMessage("Erreur lors de la construction");
        }
    };

    const cancel = async (idItem) => {
        setIsDisabled(false);
        setLoading(true)
        const data = {
            priceGold: item.priceGold,
            priceOrinia: item.priceOrinia,
            priceMetals: item.priceMetals,
            priceOrinium: item.priceOrinium,
            priceEnergy: item.priceEnergy,
        }
        const res = await getCancelBuilding(Logger, item.idPlanet, idItem, data);
        if (res) {
            setMessage(res.message);
            window.location.reload();
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    };

    return (
        <div className="containerBuilding">
            <img src={imagePath} className={"imageBuilding"} alt={item.name} />
            <div className={"containerBuildingInfo"}>
                <div className="nameBuilding">{item.name}</div>
                <div className="levelBuilding">{"Niveau : " + item.level}</div>
                <div className="levelBuilding">{"Temps de construction : " + item.timeUpgrade}</div>
                <div className="levelBuilding">
                    {"Temps restant : " + (remainingTime !== null ? formatTime(remainingTime) : "Pas de construction en cours")}
                </div>
                <div>{"Or : " + item.priceGold}</div>
                <div>{"Orinia : " + item.priceOrinia}</div>
                <div>{"Métaux : " + item.priceMetals}</div>
                <div>{"Orinium : " + item.priceOrinium}</div>
            </div>
            <BuyButton
                loading={loading}
                disabledGlobal={disabledGlobal}
                isDisabled={isDisabled}
                item={item}
                name={name}
                upgrade={upgrade}
                cancel={cancel}
                annulation={"Annuler la construction"}
                build={`Construction de ${name === null ? "" : name} en cours`}
                title={"Construire"}
            />
        </div>
    );
};

export default BuildingCard;
