import AxiosInstance from "./AxiosInstance";

export const getBuilding = async (Logger, planetId) => {

    try {
        const result = await AxiosInstance(Logger).get('/api/building/' + planetId);
        // console.log("RESULT BATIMENTS ===", result)
        return result

    } catch (error) {
        console.error("IMPOSSIBLE DE GENERER LES BATIMENTS:", error);
        return "IMPOSSIBLE DE GENERER LES BATIMENTS";
    }
}


export const getUpgradeBuilding = async (Logger, planetId, idBuilding, data) => {

    // console.log("PLANET ID ===", planetId)
    try {

        const body = {
            priceGold: data.priceGold,
            priceOrinia: data.priceOrinia,
            priceMetals: data.priceMetals,
            priceOrinium: data.priceOrinium,
            priceEnergy: data.priceEnergy,
        };

        const result = await AxiosInstance(Logger).put('/api/updateBuilding/' + planetId + '/' + idBuilding, body);
        // console.log("RESULT BATIMENTS UPGRADE ===", result)
        return result

    } catch (error) {
        console.error("IMPOSSIBLE DE UPGRADE LES BATIMENTS:", error);
        return "IMPOSSIBLE DE UPGRADE LES BATIMENTS";
    }
}

export const getCancelBuilding = async (Logger, planetId, idBuilding, data) => {

    try {

        const body = {
            priceGold: data.priceGold,
            priceOrinia: data.priceOrinia,
            priceMetals: data.priceMetals,
            priceOrinium: data.priceOrinium,
            priceEnergy: data.priceEnergy,
        };

        const result = await AxiosInstance(Logger).put('/api/cancelBuilding/' + planetId + '/' + idBuilding, body);
        return result

    } catch (error) {
        console.error("IMPOSSIBLE DE CANCEL L'ACHAT:", error);
        return "IMPOSSIBLE DE CANCEL L'ACHAT";
    }
}
