import AxiosInstance from "./AxiosInstance";

export const getUsers = async (Logger) => {

    try {

        const result = await AxiosInstance(Logger).get('/api/admin/users');
        return result

    } catch (error) {
        console.log("ERROR ===", error)
        return error
    }
}


export const resetGates = async (Logger) => {
    try {
        const result = await AxiosInstance(Logger).post(`/api/resetGate`);
        return result;

    } catch (error) {
        console.log("ERROR ===", error)
        return error
    }
}



export const getGates = async (Logger) => {
    try {
        const result = await AxiosInstance(Logger).get(`/api/admin/gates`);
        return result;

    } catch (error) {
        console.log("ERROR ===", error)
        return error
    }
}
