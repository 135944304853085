

export const getName = (name) => {
    switch (name) {
        case "workerMetals":
            return "Ouvriers Métaux";
        case "slaveMetals":
            return "Esclaves Métaux";
        case "workerGold":
            return "Ouvriers Or";
        case "slaveGold":
            return "Esclaves Or";
        case "workerOrinia":
            return "Ouvriers Orinia";
        case "slaveOrinia":
            return "Esclaves Orinia";
        case "workerOrinium":
            return "Ouvriers Orinium";
        case "slaveOrinium":
            return "Esclaves Orinium";

    }
}


export const getMineName = (name) => {
    const mapping = {
        workerMetals: "worker",
        slaveMetals: "slave",
        workerGold: "worker",
        slaveGold: "slave",
        workerOrinia: "worker",
        slaveOrinia: "slave",
        workerOrinium: "worker",
        slaveOrinium: "slave"
    };
    return mapping[name] || null; // Retourne null si le nom n'est pas trouvé dans le mapping
};
