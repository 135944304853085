import React from 'react';
import { BarLoader } from 'react-spinners';

const BuyButton = ({ loading, disabledGlobal, isDisabled, item, title, upgrade, cancel, annulation, build }) => {
    // Détermine le texte et le style du bouton en fonction des états
    const renderButtonContent = () => {
        if (loading) {
            return (
                <div className="buyButton" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <BarLoader color={"#fff"} loading={loading} size={150} />
                </div>
            );
        }

        if (item.technologyInProgress) {
            return (
                <div className="cancelButton" onClick={() => cancel(item.id)}>
                    {annulation}
                </div>
            );
        }

        if (isDisabled) {
            return (
                <div className="buyButton">
                    {"Pas assez de ressources"}
                </div>
            );
        }

        if (disabledGlobal) {
            return (
                <div className="buyButton">
                    {build}
                </div>
            );
        }

        return (
            <button className="buyButton" onClick={() => upgrade(item.id, item.name)} disabled={disabledGlobal || isDisabled}>
                {title}
            </button>
        );
    };

    return (
        <div>
            {renderButtonContent()}
        </div>
    );
};

export default BuyButton;
