import React from 'react';
import ButtonClassic from "../Utilities/ButtonClassic";
import TableAdmin from "./TableAdmin";

const UsersAdmin = ({ users, changeState }) => {

    const userColumns = [
        { header: 'ID', accessor: 'id' },
        { header: 'Username', accessor: 'username' },
        { header: 'Email', accessor: 'email' },
        { header: 'Race', accessor: 'race' },
        { header: 'Joy', accessor: 'joy' },
        { header: 'Points', accessor: 'points' },
        { header: 'User Rank', accessor: 'userRank' },
        { header: 'Planet Selected', accessor: 'planetSelected' }
    ];


    return (
        <div className={"AdminUsersContainer"}>
            <h1>Manage Users</h1>
            <div className="AdminUsersContainer">
                {users &&<TableAdmin
                    data={users}
                    columns={userColumns}
                />}
                <ButtonClassic
                    onClick={() => changeState(0)}
                    name={"Retour"}
                />
            </div>
        </div>
    );
};

export default UsersAdmin;



