import {BarLoader} from "react-spinners";


export const Loader = () => {
    return (
        <div style={{display: "flex", flex: 1, height: 15, justifyContent: "center", alignSelf: "center", alignItems: "center"}}>
            <BarLoader color={"var(--white)"} loading={true} size={250} />
        </div>
    );
}
