import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {getMine} from "../../Api/Mine";
import {BarLoader} from "react-spinners";
import {Loader} from "../../Components/Utilities/Utilities";

const ProductionScreen = ({ Logger, population, resources, planetSelected, setUpdatedResources }) => {


    const [mine, setMine] = useState(null);

    useEffect(() => {
        if (planetSelected) {
            // Simuler l'appel API pour récupérer les informations des mines
            const fetchMineData = async () => {
                const res = await getMine(Logger, planetSelected.id);
                setMine(res);
            };
            fetchMineData();
        }
    }, [planetSelected]);

    if (!mine || !population) {
        return <Loader />
    }

    // Calcul de la production horaire
    const productionPerHour = {
        metals: (mine.workerMetals + mine.slaveMetals * 2),
        gold: (mine.workerGold + mine.slaveGold * 2),
        orinia: (mine.workerOrinia + mine.slaveOrinia * 2),
        orinium: (mine.workerOrinium + mine.slaveOrinium * 2),
        population: (population.populationProduction || 0),
    };

    return (
        <div className="containerProductionScreen">
            <table className="productionTable">
                <thead className="tableHeader">
                <tr className="tableRow">
                    <th className="tableHeaderCell">Ressource</th>
                    <th className="tableHeaderCell">Ouvriers</th>
                    <th className="tableHeaderCell">Esclaves</th>
                    <th className="tableHeaderCell">Production / Heure</th>
                </tr>
                </thead>
                <tbody className="tableBody">
                <tr className="tableRow">
                    <td className="tableCell">Metals</td>
                    <td className="tableCell">{mine.workerMetals}</td>
                    <td className="tableCell">{mine.slaveMetals}</td>
                    <td className="tableCell">{productionPerHour.metals}</td>
                </tr>
                <tr className="tableRow">
                    <td className="tableCell">Gold</td>
                    <td className="tableCell">{mine.workerGold}</td>
                    <td className="tableCell">{mine.slaveGold}</td>
                    <td className="tableCell">{productionPerHour.gold}</td>
                </tr>
                <tr className="tableRow">
                    <td className="tableCell">Orinia</td>
                    <td className="tableCell">{mine.workerOrinia}</td>
                    <td className="tableCell">{mine.slaveOrinia}</td>
                    <td className="tableCell">{productionPerHour.orinia}</td>
                </tr>
                <tr className="tableRow">
                    <td className="tableCell">Orinium</td>
                    <td className="tableCell">{mine.workerOrinium}</td>
                    <td className="tableCell">{mine.slaveOrinium}</td>
                    <td className="tableCell">{productionPerHour.orinium}</td>
                </tr>
                <tr className="tableRow">
                    <td className="tableCell">Population</td>
                    <td className="tableCell" colSpan="2">-</td>
                    <td className="tableCell">{productionPerHour.population}</td>
                </tr>
                </tbody>
            </table>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        Logger: state.Logger
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: (action) => {
            dispatch(action);
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductionScreen);
